import React from 'react'

import { Box, Dialog, Typography } from '@mui/material'
import { GoogleLogin } from '@react-oauth/google'
import { useDispatch } from 'react-redux'
import useAlert from '../../../hooks/useAlert'
import { signInRequest } from '../../../store/modules/auth/actions'

export interface AlertDialogSessionExpiredProps {
  open: boolean
  onCancel: () => void
}

const AlertDialogSessionExpired: React.FC<
  React.PropsWithChildren<AlertDialogSessionExpiredProps>
> = ({ open, onCancel }) => {
  const dispatch = useDispatch()
  const { showAlert } = useAlert()

  const handleLogin = (token) => {
    dispatch(signInRequest(token))
  }
  return (
    <>
      <Dialog open={open}>
        <Box sx={{ p: 4 }}>
          <Typography variant="h5">Sua sessão expirou</Typography>
          <Typography variant="body1">
            Para continuar faça login novamente
          </Typography>
          <Box my={4} />
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <GoogleLogin
              onSuccess={(credentialResponse) => {
                handleLogin(credentialResponse.credential)
              }}
              onError={() => {
                showAlert(
                  'Ocorreu um problema ao trocar dados com o servidor',
                  'error',
                )
              }}
            />
          </Box>
        </Box>
      </Dialog>
    </>
  )
}

export default AlertDialogSessionExpired
